var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"value":_vm.visible,"width":800,"fullscreen":_vm.$vuetify.breakpoint.mobile,"scrollable":true},on:{"click:outside":function($event){return _vm.closeDialog()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeDialog()}}},[_c('v-card',_vm._b({},'v-card',_vm.$vuetify.breakpoint.mobile ? {} : { height: 500 },false),[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('spaceActivePlan.title'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-card-text',[_c('v-card',_vm._b({class:_vm.$vuetify.breakpoint.mobile
            ? 'py-0'
            : 'd-flex justify-space-around mb-6',attrs:{"elevation":"0"}},'v-card',_vm.$vuetify.breakpoint.mobile ? {} : { height: 350 },false),[_c('v-card',_vm._b({staticClass:"mb-3",attrs:{"outlined":""}},'v-card',
            _vm.$vuetify.breakpoint.mobile ? {} : { height: 360, width: 300 }
          ,false),[_c('v-card-title',{staticClass:"justify-center primary--text"},[_vm._v(" "+_vm._s(_vm.$t('spaceActivePlan.plan.title'))+" ")]),_c('v-card-text',[_c('CustomPlan',{ref:"customPlan",attrs:{"horse-qty":_vm.horseQty,"stable-qty":_vm.stableQty,"user-qty":_vm.userQty}})],1)],1),_c('v-card',_vm._b({staticClass:"mb-2",attrs:{"outlined":""}},'v-card',
            _vm.$vuetify.breakpoint.mobile ? {} : { height: 360, width: 350 }
          ,false),[_c('v-card-title',{staticClass:"justify-center primary--text"},[_vm._v(" "+_vm._s(_vm.$t('spaceActivePlan.payment'))+" ")]),_c('v-card-text',[_c('SpacePayment',{ref:"spacePayment",attrs:{"creating":_vm.creating,"errorMessage":_vm.errorMessage},on:{"form-valid":_vm.paymentFormValid}})],1)],1)],1),_c('v-btn',{staticClass:"float-right pt-0",attrs:{"color":"primary","disabled":!_vm.payForm,"loading":_vm.creating},on:{"click":function($event){return _vm.activate()}}},[_vm._v(" "+_vm._s(_vm.$t('spaceActivePlan.activate'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }