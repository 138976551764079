
import { createRole, Role } from '@/store/modules/user';
import Vue from 'vue';
import { Space } from '@/store/modules/space';
import AvatarImageKit from '@/components/AvatarImageKit.vue';
import { UserSpace } from '@/helpers/data/fauna-types';
import {
  addUserToSpace,
  removeUserFromSpace,
  updateUserRole,
  usersBySpace
} from '@/helpers/data/fauna-queries';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { validationMixin } from 'vuelidate';
import { mask } from 'vue-the-mask';
import { email, required } from 'vuelidate/lib/validators';
import { ERROR_LIST } from '@/helpers/errors';
import { mdiAccountCircle, mdiChevronDown, mdiClose, mdiDelete } from '@mdi/js';

interface Data {
  users: UserSpace[] | undefined | null;
  permission: boolean;
  add: {
    email: string;
    role: string;
  };
  addUserAlert: {
    show: boolean;
    message: TranslateResult;
    type: string;
  };
  manageUserAlert: {
    show: boolean;
    message: TranslateResult;
    type: string;
  };
  registered: boolean;
  submitted: boolean;
  removeDialog: boolean;
  removeUserText: TranslateResult;
  userToBeRemoved: UserSpace | null;
}

interface Computed {
  roles: Role[];
  rolesLocalized: { text: TranslateResult; value: string }[];
  emailValidation: TranslateResult[];
  roleValidation: TranslateResult[];
  mdiClose: string;
  mdiAccountCircle: string;
  mdiChevronDown: string;
  mdiDelete: string;
}

interface Props {
  visible: boolean;
  space: Space | null;
}

interface Methods {
  removeUserDialog(user: UserSpace): void;
  removeUser(): void;
  changeRole(roleId: string, role: string): void;
  closeDialog(): void;
  addUser(): void;
  loadUsers(id: string): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SpaceManageUsers',
  components: { AvatarImageKit },
  props: {
    visible: { required: true },
    space: { required: true }
  },
  mixins: [validationMixin],
  directives: { mask },
  validations: {
    add: {
      email: { required, email },
      role: { required }
    }
  },
  data: () => ({
    users: undefined,
    permission: false,
    add: {
      email: '',
      role: ''
    },
    addUserAlert: {
      message: '',
      type: 'error',
      show: false
    },
    manageUserAlert: {
      message: '',
      type: 'error',
      show: false
    },
    registered: false,
    submitted: false,
    removeDialog: false,
    removeUserText: '',
    userToBeRemoved: null
  }),
  computed: {
    mdiClose() {
      return mdiClose;
    },
    mdiAccountCircle() {
      return mdiAccountCircle;
    },
    mdiChevronDown() {
      return mdiChevronDown;
    },
    mdiDelete() {
      return mdiDelete;
    },
    roles() {
      return [
        createRole('administrator'),
        createRole('collaborator'),
        createRole('vet')
      ];
    },
    rolesLocalized() {
      return this.roles.map((item) => {
        return { text: this.$t(item.i18nCode), value: item.name };
      });
    },
    emailValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.add.email?.$dirty) return errors;
      !this.$v.add.email.required &&
        errors.push(this.$t('spaceManageUser.errorMessages.requiredField'));
      !this.$v.add.email.email &&
        errors.push(this.$t('spaceManageUser.errorMessages.emailMustBeValid'));
      return errors;
    },
    roleValidation() {
      const errors: TranslateResult[] = [];
      if (!this.$v.add.role?.$dirty) return errors;
      !this.$v.add.role.required &&
        errors.push(this.$t('spaceManageUser.errorMessages.requiredField'));
      return errors;
    }
  },
  methods: {
    addUser() {
      this.$v.$touch();
      this.addUserAlert.show = false;
      this.manageUserAlert.show = false;
      this.addUserAlert.message = '';
      this.submitted = true;
      if (!this.$v.$invalid && this.space) {
        addUserToSpace(this.add.email, this.add.role, this.space.id)
          .then(() => {
            if (this.space) {
              this.$store.commit('SpaceModule/increaseUser', this.space.id);
              this.addUserAlert.type = 'success';
              this.addUserAlert.show = true;
              this.addUserAlert.message = this.$t(
                'spaceManageUser.add.success'
              );
              this.loadUsers(this.space.id);
              this.submitted = false;
              this.add.role = '';
              this.add.email = '';
              this.$v.$reset();
            }
          })
          .catch((error) => {
            if (error === ERROR_LIST.SPACE_ADD_USER_DOES_NOT_EXIST) {
              this.addUserAlert.type = 'error';
              this.addUserAlert.show = true;
              this.addUserAlert.message = this.$t(
                'spaceManageUser.errorMessages.userDoesNotExist'
              );
            } else if (error === ERROR_LIST.FAUNA_PERMISSION_DENIED) {
              this.addUserAlert.type = 'error';
              this.addUserAlert.show = true;
              this.addUserAlert.message = this.$t(
                'spaceManageUser.errorMessages.NoPermission'
              );
            } else {
              this.addUserAlert.type = 'error';
              this.addUserAlert.show = true;
              this.addUserAlert.message = this.$t(
                'spaceManageUser.errorMessages.notPossibleAddUser'
              );
            }
            this.submitted = false;
          });
      } else {
        this.submitted = false;
      }
    },
    removeUserDialog(user) {
      this.addUserAlert.show = false;
      this.manageUserAlert.show = false;
      this.removeDialog = true;
      this.removeUserText = this.$t('spaceManageUser.removeUser.text', {
        userName: user.name + ' ' + user.lastName
      });
      this.userToBeRemoved = user;
    },
    removeUser() {
      this.addUserAlert.show = false;
      this.manageUserAlert.show = false;
      if (this.space && this.userToBeRemoved) {
        removeUserFromSpace(this.userToBeRemoved.roleId, this.space.id)
          .then(() => {
            if (this.users && this.userToBeRemoved) {
              this.users.splice(this.users.indexOf(this.userToBeRemoved), 1);
            }
            if (this.space) {
              this.$store.commit('SpaceModule/decreaseUser', this.space.id);
            }
            this.userToBeRemoved = null;
            this.removeDialog = false;
            this.manageUserAlert.type = 'success';
            this.manageUserAlert.show = true;
            this.manageUserAlert.message = this.$t(
              'spaceManageUser.removeUser.success'
            );
          })
          .catch((error) => {
            if (error === ERROR_LIST.SPACE_ADD_USER_DOES_NOT_EXIST) {
              this.manageUserAlert.type = 'error';
              this.manageUserAlert.show = true;
              this.manageUserAlert.message = this.$t(
                'spaceManageUser.errorMessages.userDoesNotExist'
              );
            } else if (error === ERROR_LIST.FAUNA_PERMISSION_DENIED) {
              this.manageUserAlert.type = 'error';
              this.manageUserAlert.show = true;
              this.manageUserAlert.message = this.$t(
                'spaceManageUser.errorMessages.NoPermission'
              );
            } else {
              this.$rollbar.error(error);
              this.manageUserAlert.type = 'error';
              this.manageUserAlert.show = true;
              this.manageUserAlert.message = this.$t(
                'spaceManageUser.errorMessages.notPossibleRemoveUser'
              );
            }
            this.removeDialog = false;
          });
      }
    },
    changeRole(roleId, role) {
      this.addUserAlert.show = false;
      this.manageUserAlert.show = false;
      this.manageUserAlert.message = '';
      if (this.space) {
        updateUserRole(roleId, role, this.space.id)
          .then(() => {
            if (this.space) {
              (this.$refs[roleId + '-role'] as Element[])[0].innerHTML =
                this.$t('role.' + role).toString();
              this.manageUserAlert.type = 'success';
              this.manageUserAlert.show = true;
              this.manageUserAlert.message = this.$t(
                'spaceManageUser.updateRole.success'
              );
            }
          })
          .catch((error) => {
            if (error === ERROR_LIST.SPACE_ADD_USER_DOES_NOT_EXIST) {
              this.manageUserAlert.type = 'error';
              this.manageUserAlert.show = true;
              this.manageUserAlert.message = this.$t(
                'spaceManageUser.errorMessages.userDoesNotExist'
              );
            } else if (error === ERROR_LIST.FAUNA_PERMISSION_DENIED) {
              this.manageUserAlert.type = 'error';
              this.manageUserAlert.show = true;
              this.manageUserAlert.message = this.$t(
                'spaceManageUser.errorMessages.NoPermission'
              );
            } else {
              this.$rollbar.error(error);
              this.manageUserAlert.type = 'error';
              this.manageUserAlert.show = true;
              this.manageUserAlert.message = this.$t(
                'spaceManageUser.errorMessages.notPossibleUpdateUserRole'
              );
            }
          });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.users = undefined;
      this.permission = false;
      this.addUserAlert.show = false;
      this.manageUserAlert.show = false;
    },
    loadUsers(id) {
      usersBySpace(id)
        .then((usersSpace) => {
          this.users = usersSpace;
          this.permission = true;
        })
        .catch((error) => {
          this.permission = error.code !== 110000;
          this.users = null;
        });
    }
  },
  watch: {
    space(newValue) {
      if (newValue) {
        this.loadUsers(newValue.id);
      }
    }
  }
});
