
import Vue from "vue";
import { mapState } from "vuex";
import SpaceCard from "@/components/space/SpaceCard.vue";
import NewCard from "@/components/NewCard.vue";
import SpaceCreateFlow from "@/components/space/SpaceCreateFlow.vue";
import SpaceManageUsers from "@/components/space/SpaceManageUsers.vue";
import { Space } from "@/store/modules/space";
import SpaceActivePlan from "@/components/space/SpaceActivePlan.vue";
import { TYPE } from "@/store/modules/notification";
import { User } from "@/store/modules/user";
import SpaceCancel from "@/components/space/SpaceCancel.vue";

interface Data {
  newSpaceDialog: boolean;
  activateDialog: boolean;
  cancelDialog: boolean;
  editDialog: boolean;
  manageUsersDialog: boolean;
  space: Space | null;
}

interface Computed {
  user: User;
  spaces: Space[];
}

interface Method {
  spaceCreated(): void;
  spaceActivated(): void;
  spaceCanceled(): void;
  activate(spaceId: string): void;
  edit(spaceId: string): void;
  cancel(spaceId: string): void;
  manageUsers(spaceId: string): void;
  closeDialog(): void;
}

export default Vue.extend<Data, Method, Computed, Record<string, never>>({
  //TODO when component load we should refresh spaces
  name: 'Spaces',
  components: {
    SpaceCancel,
    SpaceManageUsers,
    SpaceCard,
    NewCard,
    SpaceCreateFlow,
    SpaceActivePlan
  },
  data: () => ({
    newSpaceDialog: false,
    activateDialog: false,
    cancelDialog: false,
    editDialog: false,
    manageUsersDialog: false,
    space: null
  }),
  methods: {
    spaceCreated() {
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('spaces.create.success.message'),
        type: TYPE.SUCCESS
      });
      this.newSpaceDialog = false;
    },
    spaceActivated() {
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('spaces.activate.success.message'),
        type: TYPE.SUCCESS
      });
      this.closeDialog();
    },
    spaceCanceled() {
      this.closeDialog();
    },
    activate(spaceId) {
      this.activateDialog = true;
      this.space = this.$store.getters['SpaceModule/getById'](spaceId);
    },
    edit(spaceId) {
      this.editDialog = true;
      this.space = this.$store.getters['SpaceModule/getById'](spaceId);
    },
    cancel(spaceId) {
      this.cancelDialog = true;
      this.space = this.$store.getters['SpaceModule/getById'](spaceId);
    },
    manageUsers(spaceId) {
      this.manageUsersDialog = true;
      this.space = this.$store.getters['SpaceModule/getById'](spaceId);
    },
    closeDialog() {
      this.manageUsersDialog = false;
      this.activateDialog = false;
      this.cancelDialog = false;
      this.editDialog = false;
      this.space = null;
    }
  },
  computed: {
    ...mapState('SpaceModule', ['spaces']),
    ...mapState('UserModule', ['user'])
  }
});
