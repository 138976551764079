
import Vue from 'vue';
import { Space } from '@/store/modules/space';
import { secret } from '@/helpers/data/fauna-queries';
import axios from 'axios';
import { SPACE_CANCEL_URL } from '@/store/constants';
import { showError } from '@/helpers/utils';
import { ERROR_LIST } from '@/helpers/errors';
import { TranslateResult } from 'vue-i18n';

interface Props {
  visible: boolean;
  space: Space | null;
}
interface Data {
  loading: boolean;
  cancelSuccess: boolean;
  cancelMessage: TranslateResult | null;
}

interface Methods {
  cancelSpace(): void;
}

export default Vue.extend<Data, Methods, Record<never, never>, Props>({
  name: 'SpaceCancel',
  props: {
    visible: { required: true },
    space: { required: true }
  },
  data: () => ({
    loading: false,
    cancelSuccess: false,
    cancelMessage: null
  }),
  computed: {},
  methods: {
    cancelSpace() {
      this.cancelSuccess = false;
      this.loading = true;
      if (this.space) {
        axios
          .patch<Space>(SPACE_CANCEL_URL, {
            spaceId: this.space.id,
            code: secret
          })
          .then((res) => {
            const space: Space = res.data;
            this.$store.commit('SpaceModule/updateSpace', {
              spaceId: space.id,
              space: {
                cancelAt: space.cancelAt,
                status: space.status,
                payment: space.payment
              }
            });
            this.$emit('space-canceled');
            this.cancelMessage = this.$t('spaceCancel.success.body', {
              date: this.$dayjs(space.cancelAt).format('L')
            });
            this.cancelSuccess = true;
            this.loading = false;
          })
          .catch(() => {
            this.$emit('space-canceled');
            this.loading = false;
            this.cancelSuccess = false;
            showError(ERROR_LIST.SPACE_CANCEL_FAIL);
          });
      }
    }
  }
});
