
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'DisplayInfo',
  props: {
    title: {
      type: String as PropType<string>,
      required: true
    },
    value: {
      type: [String, Number] as PropType<string | number>,
      required: true
    },
    textStyle: {
      type: String as PropType<string>,
      required: false
    }
  },
  computed: {}
});
