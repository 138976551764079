
import { TranslateResult } from 'vue-i18n';
import { Validation, validationMixin } from 'vuelidate';
import { mask } from 'vue-the-mask';
import {
  maxLength,
  minLength,
  numeric,
  or,
  required
} from 'vuelidate/lib/validators';
import {
  amexFormat,
  cardDate,
  cardFormat,
  cnpj,
  cpf,
  numberSpace
} from '@/helpers/validations';
import axios from 'axios';
import { CARD_BRAND_URL } from '@/store/constants';
import { ValidationGroups } from 'vue/types/vue';
import vuelidateMixin from '@/helpers/vuelidateMixin';
import { Payment } from '@/helpers/payment/queries';
import dayjs from 'dayjs';

interface Props {
  creating: boolean;
  errorMessage: TranslateResult | null;
}

export interface Data {
  schemaLogo: string;
  payment: Payment;
}

interface Computed {
  cardNameErrors: TranslateResult[];
  cardNumberErrors: TranslateResult[];
  cardCvvErrors: TranslateResult[];
  cardExpireDateErrors: TranslateResult[];
  documentErrors: TranslateResult[];
}

export interface Methods {
  resetComponentData(): void;
  valid(): void;
}
export default vuelidateMixin.extend<Data, Methods, Computed, Props>({
  name: 'SpacePayment',
  props: {
    creating: { type: Boolean, default: false },
    errorMessage: { type: String, default: null }
  },
  mixins: [validationMixin],
  directives: { mask },
  validations: {
    payment: {
      card: {
        name: { required, maxLength: maxLength(30), numberSpace },
        number: {
          required,
          card: or(amexFormat, cardFormat)
        },
        expiryDate: {
          required,
          cardDate,
          length: maxLength(7),
          minValue: (value) => {
            if (value.length == 7) {
              const date = dayjs(value, 'MM/YYYY').endOf('month');
              const now = dayjs();
              return date.valueOf() >= now.valueOf();
            }
            return false;
          }
        },
        cvv: {
          required,
          numeric,
          maxLength: maxLength(4),
          minLength: minLength(3)
        }
      },
      document: { required, format: or(cnpj, cpf) }
    }
  },
  data: () => ({
    schemaLogo: 'none.png',
    payment: {
      card: { name: '', number: '', cvv: '', expiryDate: '' },
      document: ''
    }
  }),
  methods: {
    resetComponentData() {
      this.payment = {
        card: { name: '', number: '', cvv: '', expiryDate: '' },
        document: ''
      };
      this.schemaLogo = 'none.png';
      this.$v.$reset();
    },
    valid() {
      if (
        this.payment.card.name &&
        this.payment.card.number &&
        this.payment.card.cvv &&
        this.payment.card.expiryDate &&
        this.payment.document &&
        !this.$v.payment.$error
      ) {
        this.$emit('form-valid', true);
      } else {
        this.$emit('form-valid', false);
      }
    }
  },
  computed: {
    cardNameErrors() {
      const errors: TranslateResult[] = [];
      const field = this.$v.payment.card?.name as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      const reqField = this.required(field);
      reqField && errors.push(reqField);
      !field.numberSpace &&
        errors.push(this.$t('spacePayment.error.card.nameLetters'));
      !field.maxLength &&
        errors.push(this.$t('spacePayment.error.card.nameMax'));
      return errors;
    },
    cardNumberErrors() {
      const errors: TranslateResult[] = [];
      const field = this.$v.payment.card?.number as ValidationGroups &
        Validation;
      if (!field.$dirty) return [];
      const reqField = this.required(field);
      reqField && errors.push(reqField);
      !field.card &&
        errors.push(this.$t('spacePayment.error.card.numberFormat'));
      return errors;
    },
    cardCvvErrors() {
      const errors: TranslateResult[] = [];
      const field = this.$v.payment.card?.cvv as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      const reqField = this.required(field);
      reqField && errors.push(reqField);
      (!field.minLength || !field.maxLength) &&
        errors.push(this.$t('spacePayment.error.cvv.digits'));
      return errors;
    },
    cardExpireDateErrors() {
      const errors: TranslateResult[] = [];
      const field = this.$v.payment.card?.expiryDate as ValidationGroups &
        Validation;
      if (!field.$dirty) return [];
      const reqField = this.required(field);
      reqField && errors.push(reqField);
      !field.cardDate &&
        errors.push(this.$t('spacePayment.error.card.dateFormat'));
      !field.minValue &&
        errors.push(this.$t('spacePayment.error.card.dateInvalid'));
      return errors;
    },
    documentErrors() {
      const errors: TranslateResult[] = [];
      const field = this.$v.payment.document as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      const reqField = this.required(field);
      reqField && errors.push(reqField);
      !field.format &&
        errors.push(this.$t('spacePayment.error.document.invalid'));
      return errors;
    }
  },
  watch: {
    'payment.card.number'(newValue) {
      const bin = newValue.replace(/\s+/g, '');
      const oldValueParse = newValue.replace(/\s+/g, '');
      //avoid duplicate requests
      if (newValue !== oldValueParse) {
        if (!isNaN(bin) && bin.length === 6 && this.schemaLogo == 'none.png') {
          axios
            .get<string>(CARD_BRAND_URL + '?bin=' + bin)
            .then((res) => {
              this.schemaLogo = res.data + '.png';
            })
            .catch(() => {
              this.schemaLogo = 'none.png';
            });
        } else if (bin.length < 6 && this.schemaLogo !== 'none.png') {
          this.schemaLogo = 'none.png';
        }
      } else {
        this.schemaLogo = 'none.png';
      }
    }
  }
});
