var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AvatarCard',{attrs:{"avatar":_vm.space.avatar ? _vm.space.avatar : '',"icon":_vm.space.avatar ? '' : _vm.mdiBarn,"color":_vm.space.avatar ? '' : 'teal',"badge-size":"big","height":215,"link":_vm.spaceEnter ? '/' + this.space.id : null}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('v-card-title',{staticClass:"pt-1 pb-0 pr-1 justify-end",class:_vm.$vuetify.breakpoint.mdAndDown ? 'text-subtitle-1' : ''},[(_vm.spaceEnter)?_c('v-btn',{staticClass:"float-right",attrs:{"text":"","color":"primary","to":'/' + this.space.id,"loading":_vm.clickSpace},on:{"click":function($event){_vm.clickSpace = true}}},[_vm._v(" "+_vm._s(_vm.spaceName(16))+" ")]):_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.spaceName(19))+" ")]),_c('v-menu',{attrs:{"offset-y":"","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(['owner', 'administrator'].includes(_vm.space.role.name))?_c('v-btn',_vm._g({attrs:{"color":"black","icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDotsVertical))])],1):_vm._e()]}}])},[_c('v-list',{attrs:{"dense":""}},[(
              ['owner', 'administrator'].includes(_vm.space.role.name) &&
              _vm.spaceEnter
            )?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('space-edit', _vm.space.id)}}},[_c('PlaceHolderNewFeature'),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('space-card.edit'))+" ")])],1):_vm._e(),(
              ['owner', 'administrator'].includes(_vm.space.role.name) &&
              _vm.spaceEnter
            )?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('space-manage-users', _vm.space.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('space-card.manageUsers'))+" ")])],1):_vm._e(),(
              _vm.space.plan &&
              _vm.space.plan.type === _vm.planType.PAID &&
              false &&
              ['owner'].includes(_vm.space.role.name)
            )?_c('v-list-item',{attrs:{"href":_vm.paymentLink,"target":"_blank","disabled":_vm.space.payment && _vm.space.payment.status !== _vm.paymentStatus.ACTIVE}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('space-card.managePayment'))+" ")])],1):_vm._e(),(
              _vm.space.plan &&
              _vm.space.plan.type === _vm.planType.PAID &&
              false &&
              ['owner'].includes(_vm.space.role.name)
            )?_c('v-list-item',{attrs:{"disabled":_vm.space.payment && _vm.space.payment.status !== _vm.paymentStatus.ACTIVE},on:{"click":function($event){return _vm.$emit('space-cancel', _vm.space.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('space-card.cancel'))+" ")])],1):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"d-flex justify-end mb-6",attrs:{"max-width":_vm.$vuetify.breakpoint.mdAndDown
          ? _vm.$vuetify.breakpoint.smAndDown
            ? 220
            : 200
          : 280,"elevation":"0"}},[_c('DisplayInfo',{attrs:{"title":_vm.$t('space-card.space-owner'),"value":_vm.space.owner}}),_c('DisplayInfo',{attrs:{"title":_vm.$t('space-card.planType'),"value":_vm.planTypeStatus}})],1)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[(
        (_vm.space.status === _vm.spaceStatus.INACTIVE ||
          _vm.space.status === _vm.spaceStatus.CANCELED) &&
        false
      )?_c('v-overlay',{attrs:{"absolute":"","color":"black","opacity":"0.8","z-index":"4"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"70%"}},[_vm._v(" "+_vm._s(_vm.inactive.message)+" ")]),_c('br'),(['owner'].includes(_vm.space.role.name))?_c('v-btn',{staticClass:"mx-auto black--text",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('space-activate', _vm.space.id)}}},[_vm._v(" "+_vm._s(_vm.inactive.button)+" ")]):_vm._e()],1)]):_vm._e(),(
        (!_vm.space.cancelAt &&
          _vm.space.plan &&
          _vm.space.plan.type === _vm.planType.PAID &&
          _vm.space.status === _vm.spaceStatus.ACTIVE &&
          _vm.$vuetify.breakpoint.smAndUp) ||
        true
      )?_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('br')])],1):_vm._e(),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"pt-0 pb-0 mt-0",attrs:{"cols":"8"}},[_c('v-simple-table',{staticClass:"pr-3",attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t('space-card.stables'))+" ")]),_c('th',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.$tc('space-card.horses', 2))+" ")]),_c('th',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t('space-card.users'))+" ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.space.stableNumber)+" / "+_vm._s(_vm.space.plan ? _vm.space.plan.stable : '∞')+" ")]),_c('td',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.space.horseNumber)+" / "+_vm._s(_vm.space.plan ? _vm.space.plan.horse : '∞')+" ")]),_c('td',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.space.userNumber)+" / "+_vm._s(_vm.space.plan ? _vm.space.plan.user : '∞')+" ")])])])])],1),_c('v-col',{staticClass:"pt-0 pb-0 mt-0",attrs:{"cols":"4"}},[_c('DisplayInfo',{attrs:{"title":_vm.$t('space-card.access-level'),"value":_vm.$t(_vm.space.role.i18nCode)}})],1)],1),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"pt-0 mt-0 body-2 text-center",attrs:{"cols":"12"}},[(
            _vm.space.cancelAt &&
            _vm.space.status === _vm.spaceStatus.PENDING_CANCELLATION &&
            false
          )?_c('v-chip',{attrs:{"outlined":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('space-card.cancelingAt', { time: _vm.$dayjs(_vm.space.cancelAt).fromNow() }))+" ")]):(
            _vm.space.plan &&
            _vm.space.plan.type === _vm.planType.FREE &&
            _vm.space.status === _vm.spaceStatus.ACTIVE &&
            false
          )?_c('v-chip',{attrs:{"outlined":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('space-card.freePlanFinishAt', { time: _vm.$dayjs(_vm.space.createdAt).add(30, 'days').fromNow() }))+" ")]):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }