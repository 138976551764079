import axios from 'axios';
import { Plan, SpaceCreate } from '@/store/modules/space';
import { PAYMENT_URL } from '@/store/constants';
import { secret } from '@/helpers/data/fauna-queries';
import dayjs from 'dayjs';
import _ from 'lodash';

export type Payment = {
  card: {
    name: string;
    number: string;
    cvv: string;
    expiryDate: string;
  };
  document: string;
};

export function executePay(
  spaceId: string,
  payment: Payment,
  plan?: Plan
): Promise<SpaceCreate> {
  return new Promise((resolve, reject) => {
    const payFormat = sanitizePayment(payment);
    axios
      .post<SpaceCreate>(PAYMENT_URL, {
        spaceId: spaceId,
        code: secret,
        payment: payFormat,
        ...(plan ? { plan: plan } : {})
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function sanitizePayment(payment: Payment): Payment {
  const payFormat = _.cloneDeep(payment);
  payFormat.card.number = payFormat.card.number.replace(
    new RegExp('\\s', 'gi'),
    ''
  );
  payFormat.card.expiryDate = dayjs(
    payFormat.card.expiryDate,
    'MM/YYYY'
  ).format('YYYY-MM');
  payFormat.document = payFormat.document.replace(new RegExp('\\D', 'gi'), '');
  return payFormat;
}
