
import { PLAN, Space, SPACE_STATUS, SpaceCreate } from "@/store/modules/space";
import { createRole, User } from "@/store/modules/user";
import { mapState } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { Vue } from "vue/types/vue";
import DisplayInfo from "@/components/DisplayInfo.vue";
import FileInputImage, { FileInputImageType } from "@/components/FileInputImage.vue";
import AvatarImage from "@/components/AvatarImage.vue";
import { showError } from "@/helpers/utils";
import vuelidateMixin from "@/helpers/vuelidateMixin";
import CustomPlan, {
  Data as CustomPlanData,
  Methods as CustomPlanMethods
} from "@/components/space/CustomPlan.vue";
import SpacePayment, {
  Data as PayData,
  Methods as PayMethods
} from "@/components/space/SpacePayment.vue";
import { executePay, Payment, sanitizePayment } from "@/helpers/payment/queries";
import FormDialog from "@/components/FormDialog.vue";
import { mdiBarn, mdiClose } from "@mdi/js";

interface Data {
  step: number;
  space: Space;
  spaceImg: File | null;
  creating: boolean;
  errorMessage: string | null;
  payForm: boolean;
}

interface Computed {
  user: User;
  step1: boolean;
  step3: boolean;
  imageUrl: string;
  mdiClose: string;
  mdiBarn: string;
}

interface Methods {
  create(): void;
  spaceCompleted(result: SpaceCreate): void;
  step2Click(): void;
  setImage(file: File): void;
  paymentFormValid(status: boolean): void;
}

interface Props {
  dialog: boolean;
}

export default vuelidateMixin.extend<Data, Methods, Computed, Props>({
  name: 'SpaceCreateFlow',
  components: {
    SpacePayment,
    CustomPlan,
    DisplayInfo,
    FileInputImage,
    AvatarImage,
    FormDialog
  },
  mixins: [validationMixin],
  directives: { mask },
  validations: {
    space: {
      name: { required }
    }
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    step: 1,
    creating: false,
    space: {
      id: '',
      name: '',
      role: createRole('owner'),
      owner: '',
      horseNumber: 0,
      stableNumber: 0,
      userNumber: 0,
      plan: {
        horse: 3,
        stable: 2,
        user: 10,
        type: null,
        price: 0
      },
      status: SPACE_STATUS.INACTIVE
    },
    spaceImg: null,
    planEnum: PLAN,
    errorMessage: null,
    payForm: false
  }),
  methods: {
    create() {
      this.creating = true;
      this.errorMessage = null;
      this.space.plan.price = parseFloat(this.space.plan.price.toFixed(2));
      if (this.space.id) {
        executePay(
          this.space.id,
          (this.$refs.spacePayment as Vue & PayData).payment,
          this.space.plan
        )
          .then((result) => {
            this.spaceCompleted(result);
            if (!result.error) {
              result.space.owner = this.user.name;
              result.space.role = createRole('owner');
              this.$store.commit('SpaceModule/updateSpace', {
                spaceId: result.space.id,
                space: result.space
              });
            }
          })
          .catch((error) => {
            showError(error);
            this.creating = false;
          });
      } else {
        let payFormat: Payment | null = null;
        if (this.space.plan.type === PLAN.PAID) {
          payFormat = sanitizePayment(
            (this.$refs.spacePayment as Vue & PayData).payment
          );
        }
        this.space.owner = this.user.name;
        const spaceNew = Object.assign({}, this.space);
        this.$store
          .dispatch('SpaceModule/createSpace', {
            space: spaceNew,
            avatar: this.spaceImg,
            payment: this.space.plan.type === PLAN.PAID ? payFormat : null
          })
          .then((result) => {
            this.spaceCompleted(result);
          })
          .catch((error) => {
            this.$rollbar.error(error);
            showError(error);
            this.creating = false;
          });
      }
    },
    spaceCompleted(result) {
      if (!result.error) {
        this.creating = false;
        this.$emit('space-created');
        this.space.name = '';
        this.space.id = '';
        this.spaceImg = null;
        const customPlan = this.$refs.customPlan as Vue &
          CustomPlanData &
          CustomPlanMethods;
        customPlan.resetComponentData();
        this.space.plan = {
          horse: customPlan.horse.quantity,
          stable: customPlan.stable.quantity,
          user: customPlan.user.quantity,
          type: null,
          price:
            customPlan.horse.price +
            customPlan.stable.price +
            customPlan.user.price
        };
        const pay = this.$refs.spacePayment as Vue & PayMethods;
        if (pay) {
          pay.resetComponentData();
        }
        this.step = 1;
        (this.$refs.fileImage as FileInputImageType).clear();
        this.errorMessage = null;
        this.$v.$reset();
      } else {
        if (result.space.id) {
          this.creating = false;
          this.space.id = result.space.id;
          this.errorMessage = this.$t(
            'spaces.create.errors.' + result.error.field
          ).toString();
        }
      }
    },
    step2Click() {
      if (this.space.plan.type === PLAN.FREE) {
        this.space.plan.horse = 99;
        this.space.plan.stable = 99;
        this.space.plan.user = 99;
        this.space.plan.price = 0;
      } else {
        const customPlan = this.$refs.customPlan as Vue & CustomPlanData;
        this.space.plan.horse = customPlan.horse.quantity;
        this.space.plan.stable = customPlan.stable.quantity;
        this.space.plan.user = customPlan.user.quantity;
        this.space.plan.price =
          customPlan.horse.price +
          customPlan.stable.price +
          customPlan.user.price;
      }
      this.step = 3;
    },
    setImage(file) {
      this.spaceImg = file;
    },
    paymentFormValid(status) {
      this.payForm = status;
    }
  },
  computed: {
    ...mapState('UserModule', ['user']),
    mdiClose() {
      return mdiClose;
    },
    mdiBarn() {
      return mdiBarn;
    },
    step1() {
      return !!(!this.$data.space.name || this.$v.space.name?.$error);
    },
    step3() {
      if (this.space.plan.type === PLAN.FREE) {
        return false;
      }
      return !this.payForm;
    },
    imageUrl() {
      if (this.spaceImg) {
        return URL.createObjectURL(this.spaceImg);
      } else {
        return '';
      }
    }
  }
});
