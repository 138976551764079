var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"value":_vm.dialog,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"width":800,"persistent":true,"scrollable":true}},[_c('v-card',{attrs:{"height":_vm.$vuetify.breakpoint.mobile ? 710 : 610}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('spaces.add-space'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-card-text',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pa-0' : ''},[_c('v-stepper',_vm._b({attrs:{"slot":"formDialog","flat":_vm.$vuetify.breakpoint.mobile},slot:"formDialog",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},'v-stepper',
          _vm.$vuetify.breakpoint.mobile
            ? { width: _vm.$vuetify.breakpoint.width }
            : {}
        ,false),[(!_vm.$vuetify.breakpoint.mobile)?_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.step > 1,"step":"1"}},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.selectData'))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.selectPlan'))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.step > 3,"step":"3"}},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.confirmation'))+" ")])],1):_vm._e(),_c('v-stepper-items',[_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'pa-3' : '',attrs:{"step":"1"}},[_c('v-card',_vm._b({attrs:{"elevation":"0"}},'v-card',
                _vm.$vuetify.breakpoint.mobile
                  ? { height: _vm.$vuetify.breakpoint.height * 0.82 }
                  : { height: 350 }
              ,false),[_c('v-text-field',{attrs:{"label":_vm.$t('spaces.create.fields.name'),"error-messages":_vm.required(_vm.$v.space.name),"required":""},on:{"input":function($event){return _vm.$v.space.name.$touch()},"blur":function($event){return _vm.$v.space.name.$touch()}},model:{value:(_vm.space.name),callback:function ($$v) {_vm.$set(_vm.space, "name", $$v)},expression:"space.name"}}),_c('FileInputImage',{ref:"fileImage",attrs:{"label":_vm.$t('spaces.create.fields.avatar'),"icon":_vm.mdiBarn},on:{"image-selected":_vm.setImage}})],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","disabled":_vm.step1},on:{"click":function($event){_vm.step = 2}}},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.button.continue'))+" ")])],1),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'pa-3' : '',attrs:{"step":"2"}},[_c('v-card',_vm._b({class:_vm.$vuetify.breakpoint.mobile
                  ? ''
                  : 'd-flex justify-space-around mb-6',attrs:{"elevation":"0"}},'v-card',
                _vm.$vuetify.breakpoint.mobile
                  ? { height: _vm.$vuetify.breakpoint.height * 0.83 }
                  : { height: 350 }
              ,false),[_c('v-hover',{attrs:{"disabled":!!(_vm.user && _vm.user.freePlan)},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',_vm._b({staticClass:"mb-5",attrs:{"elevation":hover || _vm.space.plan.type === _vm.planEnum.FREE ? 12 : 2,"outlined":""}},'v-card',
                    _vm.$vuetify.breakpoint.mobile
                      ? {}
                      : { height: 360, width: 200 }
                  ,false),[_c('v-card-title',{staticClass:"justify-center primary--text"},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.plan.free.title'))+" ")]),(_vm.user && !_vm.user.freePlan)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('spaces.create.plan.free.text'))+" ")]):_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('spaces.create.plan.free.blocked'))+" ")]),_c('v-row',{staticClass:"pb-3",style:(_vm.$vuetify.breakpoint.mobile ? {} : { height: '235px' }),attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align-self":"end","align":"center"}},[(_vm.user && !_vm.user.freePlan)?_c('v-btn',{staticClass:"black--text",attrs:{"disabled":_vm.space.plan.type === _vm.planEnum.FREE,"color":"secondary"},on:{"click":function($event){_vm.space.plan.type = _vm.planEnum.FREE}}},[_vm._v(" "+_vm._s(_vm.space.plan.type === _vm.planEnum.FREE ? _vm.$t('spaces.create.button.selected') : _vm.$t('spaces.create.button.select'))+" ")]):_vm._e()],1)],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [(false)?_c('v-card',_vm._b({staticClass:"mb-5",attrs:{"elevation":hover || _vm.space.plan.type === _vm.planEnum.PAID ? 12 : 2,"outlined":""}},'v-card',
                    _vm.$vuetify.breakpoint.mobile
                      ? {}
                      : { height: 360, width: 300 }
                  ,false),[_c('v-card-title',{staticClass:"justify-center primary--text"},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.plan.custom.title'))+" ")]),_c('v-card-text',[_c('CustomPlan',{ref:"customPlan"})],1),_c('br'),_c('v-row',{staticClass:"pb-3",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align-self":"end","align":"center"}},[_c('v-btn',{staticClass:"black--text",attrs:{"disabled":_vm.space.plan.type === _vm.planEnum.PAID,"color":"secondary"},on:{"click":function($event){_vm.space.plan.type = _vm.planEnum.PAID}}},[_vm._v(" "+_vm._s(_vm.space.plan.type === _vm.planEnum.PAID ? _vm.$t('spaces.create.button.selected') : _vm.$t('spaces.create.button.select'))+" ")])],1)],1)],1):_vm._e()]}}])})],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","disabled":!_vm.space.plan.type},on:{"click":function($event){return _vm.step2Click()}}},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.button.continue'))+" ")]),_c('v-btn',{on:{"click":function($event){_vm.step = _vm.step - 1}}},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.button.return'))+" ")])],1),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'pa-3' : '',attrs:{"step":"3"}},[_c('v-card',_vm._b({class:_vm.$vuetify.breakpoint.mobile
                  ? ''
                  : 'd-flex justify-space-around mb-6',attrs:{"elevation":"0"}},'v-card',
                _vm.$vuetify.breakpoint.mobile
                  ? { height: _vm.$vuetify.breakpoint.height * 0.83 }
                  : { height: 350 }
              ,false),[_c('v-card',_vm._b({staticClass:"mb-5",attrs:{"outlined":""}},'v-card',
                  _vm.$vuetify.breakpoint.mobile
                    ? {}
                    : { height: 350, width: 250 }
                ,false),[_c('v-card-title',{staticClass:"justify-center primary--text"},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.spaceData'))+" ")]),_c('div',{staticClass:"d-flex flex-row align-start pl-2 pt-3"},[_c('AvatarImage',{attrs:{"avatar":_vm.imageUrl,"icon":_vm.imageUrl ? '' : _vm.mdiBarn,"color":_vm.imageUrl ? 'white' : 'teal',"contain":true,"badgeSize":"small"}}),_c('DisplayInfo',{attrs:{"title":_vm.$t('spaces.create.fields.name') + ':',"value":_vm.space.name,"text-style":"max-width: 4rem;"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8","md":"12"}},[_c('v-card-subtitle',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.plan.title'))+" ")]),_c('v-simple-table',{staticClass:"px-4",attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.$tc('spaces.create.fields.horses', 2))+" ")]),_c('th',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.fields.stables'))+" ")]),_c('th',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.fields.users'))+" ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.space.plan.horse)+" ")]),_c('td',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.space.plan.stable)+" ")]),_c('td',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.space.plan.user)+" ")])])])])],1),_c('v-col',{attrs:{"cols":"4","md":"12"}},[_c('v-card-subtitle',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.price'))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.space.plan.price.toLocaleString(_vm.$i18n.locale, { style: 'currency', currency: 'BRL' }))+" ")])],1)],1)],1),_c('v-card',_vm._b({staticClass:"mb-5",attrs:{"outlined":""}},'v-card',
                  _vm.$vuetify.breakpoint.mobile
                    ? {}
                    : { height: 350, width: 400 }
                ,false),[_c('v-card-title',{staticClass:"justify-center primary--text"},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.payment.title'))+" ")]),_c('v-card-text',[(_vm.space.plan.type === _vm.planEnum.FREE)?_c('section',[_c('v-row',{staticStyle:{"height":"300px"}},[(_vm.creating)?_c('v-col',{attrs:{"align-self":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"size":100,"width":7,"color":"secondary","indeterminate":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('spaces.create.processing')))])],1):_vm._e(),(!_vm.creating)?_c('v-col',{domProps:{"innerHTML":_vm._s(_vm.$t('spaces.create.payment.free'))}}):_vm._e()],1)],1):_c('SpacePayment',{ref:"spacePayment",attrs:{"creating":_vm.creating,"errorMessage":_vm.errorMessage},on:{"form-valid":_vm.paymentFormValid}})],1)],1)],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","disabled":_vm.step3,"loading":_vm.creating},on:{"click":_vm.create}},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.button.create'))+" ")]),_c('v-btn',{on:{"click":function($event){_vm.step = _vm.step - 1}}},[_vm._v(" "+_vm._s(_vm.$t('spaces.create.button.return'))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }