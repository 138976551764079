
import Vue from 'vue';
import { PLAN, Space } from '@/store/modules/space';
import CustomPlan, {
  Data as CustomPlanData,
  Computed as CustomPlanComputed,
  Methods as CustomPlanMethods
} from '@/components/space/CustomPlan.vue';
import SpacePayment, {
  Data as PayData,
  Methods as PayMethods
} from '@/components/space/SpacePayment.vue';
import { executePay } from '@/helpers/payment/queries';
import { createRole, User } from '@/store/modules/user';
import { showError } from '@/helpers/utils';
import { mapState } from 'vuex';
import { mdiClose } from '@mdi/js';

interface Props {
  visible: boolean;
  space: Space;
}

interface Data {
  creating: boolean;
  errorMessage: string | null;
  payForm: boolean;
  horseQty: number | null;
  stableQty: number | null;
  userQty: number | null;
}

interface Computed {
  user: User;
  mdiClose: string;
}

interface Methods {
  closeDialog(): void;
  activate(): void;
  paymentFormValid(status: boolean): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SpaceActivePlan',
  props: {
    visible: { required: true },
    space: { required: true }
  },
  components: {
    CustomPlan,
    SpacePayment
  },
  data: () => ({
    creating: false,
    errorMessage: null,
    payForm: false,
    horseQty: 3,
    stableQty: 2,
    userQty: 10
  }),
  computed: {
    ...mapState('UserModule', ['user']),
    mdiClose() {
      return mdiClose;
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    activate() {
      this.creating = true;
      const customPlan = this.$refs.customPlan as Vue &
        CustomPlanData &
        CustomPlanComputed;
      const plan = {
        horse: customPlan.horse.quantity,
        stable: customPlan.stable.quantity,
        user: customPlan.user.quantity,
        price: customPlan.totalPrice,
        type: PLAN.PAID
      };
      const spaceUpdate = Object.assign({}, this.space);
      spaceUpdate.plan = plan;
      this.$store.commit('SpaceModule/updateSpace', {
        spaceId: this.space.id,
        space: spaceUpdate
      });
      this.horseQty = customPlan.horse.quantity;
      this.stableQty = customPlan.stable.quantity;
      this.userQty = customPlan.user.quantity;
      executePay(
        this.space.id,
        (this.$refs.spacePayment as Vue & PayData).payment,
        spaceUpdate.plan
      )
        .then((result) => {
          if (!result.error) {
            result.space.owner = this.user.name;
            result.space.role = createRole('owner');
            this.$store.commit('SpaceModule/updateSpace', {
              spaceId: result.space.id,
              space: result.space
            });
            this.creating = false;
            this.$emit('space-created');
            const customPlan = this.$refs.customPlan as Vue &
              CustomPlanData &
              CustomPlanMethods;
            customPlan.resetComponentData();
            const pay = this.$refs.spacePayment as Vue & PayMethods;
            pay.resetComponentData();
            this.errorMessage = null;
            this.$emit('space-activated');
          } else {
            if (result.space.id) {
              this.creating = false;
              this.errorMessage = this.$t(
                'spaces.create.errors.' + result.error.field
              ).toString();
            }
          }
        })
        .catch((error) => {
          showError(error);
          this.creating = false;
        });
    },
    paymentFormValid(status) {
      this.payForm = status;
    }
  },
  watch: {
    space(newValue) {
      if (newValue && newValue.plan) {
        this.horseQty = newValue.plan.horse;
        this.stableQty = newValue.plan.stable;
        this.userQty = newValue.plan.user;
      } else {
        this.horseQty = null;
        this.stableQty = null;
        this.userQty = null;
      }
    }
  }
});
