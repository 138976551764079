
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { mask } from 'vue-the-mask';
import { integer, maxValue, minValue, numeric } from 'vuelidate/lib/validators';
import { mdiMinus, mdiPlus } from '@mdi/js';

interface Unit {
  unitMin: number;
  unitMax: number;
  price: number;
}

export interface Props {
  horseQty: number;
  stableQty: number;
  userQty: number;
}
export interface Data {
  horse: { quantity: number; price: number };
  stable: { quantity: number; price: number };
  user: { quantity: number; price: number };
  horseBand: Unit[];
  stableBand: Unit[];
  userBand: Unit[];
}

export interface Computed {
  totalPrice: number;
  mdiPlus: string;
  mdiMinus: string;
}

export interface Methods {
  increase(slug: 'horse' | 'stable' | 'user'): void;
  decrease(slug: 'horse' | 'stable' | 'user'): void;
  resetComponentData(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CustomPlan',
  mixins: [validationMixin],
  directives: { mask },
  validations: {
    horse: {
      quantity: {
        minValue: minValue(3),
        maxValue: maxValue(50),
        numeric,
        integer
      }
    },
    stable: {
      quantity: {
        minValue: minValue(2),
        maxValue: maxValue(10),
        numeric,
        integer
      }
    },
    user: {
      quantity: {
        minValue: minValue(10),
        maxValue: maxValue(100),
        numeric,
        integer
      }
    }
  },
  props: {
    horseQty: { default: 3, required: false },
    stableQty: { default: 2, required: false },
    userQty: { default: 10, required: false }
  },
  data: () => ({
    horse: { quantity: 3, price: 24.9 },
    stable: { quantity: 2, price: 15 },
    user: { quantity: 10, price: 10 },
    horseBand: [
      {
        unitMin: 3,
        unitMax: 5,
        price: 8.3
      },
      {
        unitMin: 6,
        unitMax: 20,
        price: 7.9
      },
      {
        unitMin: 21,
        unitMax: 40,
        price: 7.73
      },
      {
        unitMin: 41,
        unitMax: 60,
        price: 7.49
      },
      {
        unitMin: 61,
        unitMax: 100,
        price: 7.07
      }
    ],
    stableBand: [
      {
        unitMin: 2,
        unitMax: 5,
        price: 7.5
      },
      {
        unitMin: 6,
        unitMax: 10,
        price: 7.11
      },
      {
        unitMin: 11,
        unitMax: 20,
        price: 6.96
      },
      {
        unitMin: 21,
        unitMax: 40,
        price: 6.74
      },
      {
        unitMin: 41,
        unitMax: 100,
        price: 6.36
      }
    ],
    userBand: [
      {
        unitMin: 10,
        unitMax: 100,
        price: 1
      }
    ]
  }),
  methods: {
    increase(slug) {
      const maxValue = this.$v[slug].quantity?.$params.maxValue.max;
      const value = Number(this[slug].quantity as number);
      if (value < maxValue) {
        (this[slug].quantity as number) = value + 1;
      }
    },
    decrease(slug) {
      const minValue = this.$v[slug].quantity?.$params.minValue.min;
      const value = Number(this[slug].quantity as number);
      if (value > minValue) {
        (this[slug].quantity as number) = value - 1;
      }
    },
    resetComponentData() {
      this.horse = { quantity: 3, price: 24.9 };
      this.stable = { quantity: 2, price: 15 };
      this.user = { quantity: 10, price: 10 };
      this.$v.$reset();
    }
  },
  computed: {
    mdiPlus() {
      return mdiPlus;
    },
    mdiMinus() {
      return mdiMinus;
    },
    totalPrice() {
      return this.horse.price + this.stable.price + this.user.price;
    }
  },
  watch: {
    'horseQty'(newValue) {
      this.horse.quantity = newValue;
    },
    'stableQty'(newValue) {
      this.stable.quantity = newValue;
    },
    'userQty'(newValue) {
      this.user.quantity = newValue;
    },
    'horse.quantity'(newValue) {
      const priceBand = this.horseBand.find((data) => {
        return newValue >= data.unitMin && newValue <= data.unitMax;
      });
      let price = (priceBand && priceBand.price) || 5;
      this.horse.price = newValue * price;
    },
    'stable.quantity'(newValue) {
      const priceBand = this.stableBand.find((data) => {
        return newValue >= data.unitMin && newValue <= data.unitMax;
      });
      let price = (priceBand && priceBand.price) || 3;
      this.stable.price = newValue * price;
    },
    'user.quantity'(newValue) {
      const priceBand = this.userBand.find((data) => {
        return newValue >= data.unitMin && newValue <= data.unitMax;
      });
      let price = (priceBand && priceBand.price) || 6;
      this.user.price = newValue * price;
    }
  }
});
