var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.creating)?_c('section',[_c('v-row',{staticStyle:{"height":"300px"},attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align-self":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"size":100,"width":7,"color":"secondary","indeterminate":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('spacePayment.processing')))])],1)],1)],1):_c('section',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('spacePayment.name'),"required":"","error-messages":_vm.cardNameErrors},on:{"input":function($event){_vm.$v.payment.card.name.$touch();
            _vm.valid();},"blur":function($event){_vm.$v.payment.card.name.$touch();
            _vm.valid();}},model:{value:(_vm.payment.card.name),callback:function ($$v) {_vm.$set(_vm.payment.card, "name", $$v)},expression:"payment.card.name"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"10"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#### ###### ##### ', '#### #### #### ####']),expression:"['#### ###### ##### ', '#### #### #### ####']"}],attrs:{"dense":"","label":_vm.$t('spacePayment.card'),"required":"","error-messages":_vm.cardNumberErrors},on:{"input":function($event){_vm.$v.payment.card.number.$touch();
            _vm.valid();},"blur":function($event){_vm.$v.payment.card.number.$touch();
            _vm.valid();}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-img',{attrs:{"width":"24","height":"24","src":require('@/assets/schema/' + _vm.schemaLogo)}})]},proxy:true}]),model:{value:(_vm.payment.card.number),callback:function ($$v) {_vm.$set(_vm.payment.card, "number", $$v)},expression:"payment.card.number"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"5"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/####'),expression:"'##/####'"}],attrs:{"dense":"","label":_vm.$t('spacePayment.expiryDate'),"error-messages":_vm.cardExpireDateErrors},on:{"input":function($event){_vm.$v.payment.card.expiryDate.$touch();
            _vm.valid();},"blur":function($event){_vm.$v.payment.card.expiryDate.$touch();
            _vm.valid();}},model:{value:(_vm.payment.card.expiryDate),callback:function ($$v) {_vm.$set(_vm.payment.card, "expiryDate", $$v)},expression:"payment.card.expiryDate"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"5"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###', '####']),expression:"['###', '####']"}],attrs:{"dense":"","label":_vm.$t('spacePayment.cvv'),"error-messages":_vm.cardCvvErrors},on:{"input":function($event){_vm.$v.payment.card.cvv.$touch();
            _vm.valid();},"blur":function($event){_vm.$v.payment.card.cvv.$touch();
            _vm.valid();}},model:{value:(_vm.payment.card.cvv),callback:function ($$v) {_vm.$set(_vm.payment.card, "cvv", $$v)},expression:"payment.card.cvv"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"10"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],attrs:{"dense":"","label":_vm.$t('spacePayment.document'),"error-messages":_vm.documentErrors},on:{"input":function($event){_vm.$v.payment.document.$touch();
            _vm.valid();},"blur":function($event){_vm.$v.payment.document.$touch();
            _vm.valid();}},model:{value:(_vm.payment.document),callback:function ($$v) {_vm.$set(_vm.payment, "document", $$v)},expression:"payment.document"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"dense":"","type":"error","outlined":"","value":_vm.errorMessage != null}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-img',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage == null),expression:"errorMessage == null"}],attrs:{"src":require("@/assets/galax_pay.png"),"height":"30","contain":""}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }