
import AvatarCard from "@/components/AvatarCard.vue";
import Vue from "vue";
import { PAYMENT_STATUS, PLAN, Space, SPACE_STATUS } from "@/store/modules/space";
import DisplayInfo from "@/components/DisplayInfo.vue";
import { TranslateResult } from "vue-i18n";
import { GALAXPAY_ID } from "@/store/constants";
import { shortStringDots } from "@/helpers/utils";
import PlaceHolderNewFeature from "@/components/PlaceHolderNewFeature.vue";
import { mdiBarn, mdiDotsVertical } from "@mdi/js";

interface Props {
  space: Space;
}

interface Data {
  clickSpace: boolean;
  spaceStatus: typeof SPACE_STATUS;
  paymentStatus: typeof PAYMENT_STATUS;
  planType: typeof PLAN;
}

interface Computed {
  planTypeStatus: TranslateResult;
  paymentLink: string;
  inactive: { message: TranslateResult; button: TranslateResult };
  spaceEnter: boolean;
  mdiBarn: string;
  mdiDotsVertical: string;
}
interface Methods {
  spaceName(n: number): string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SpaceCard',
  components: { PlaceHolderNewFeature, AvatarCard, DisplayInfo },
  props: {
    space: {
      type: Object
    }
  },
  data: () => ({
    clickSpace: false,
    spaceStatus: SPACE_STATUS,
    paymentStatus: PAYMENT_STATUS,
    planType: PLAN
  }),
  methods: {
    spaceName(n) {
      return shortStringDots(this.space.name, n);
    }
  },
  computed: {
    mdiBarn() {
      return mdiBarn;
    },
    mdiDotsVertical() {
      return mdiDotsVertical;
    },
    planTypeStatus() {
      const spaceType = this.space.plan
        ? this.$t('plan.' + this.space.plan.type)
        : this.$t('plan.super');

      let spaceStatus: TranslateResult = '';
      if (this.space.plan && this.space.plan.type === PLAN.PAID) {
        spaceStatus = this.space.payment
          ? '(' + this.$t('payment.status.' + this.space.payment.status) + ')'
          : '';
      }
      return `${spaceType} ${spaceStatus}`;
    },
    paymentLink() {
      return `https://gpay.com.br/c/${GALAXPAY_ID}/${this.$props.space.payment?.galaxPayId}/`;
    },
    inactive() {
      if (this.space.plan && this.space.plan.type === PLAN.FREE) {
        return {
          message: this.$t('space-card.freeEnded'),
          button: this.$t('space-card.activate')
        };
      }
      if (
        this.space.payment &&
        this.space.payment.status === PAYMENT_STATUS.CANCELED
      ) {
        return {
          message: this.$t('space-card.planCanceled'),
          button: this.$t('space-card.reActivate')
        };
      }
      return {
        message: this.$t('space-card.paymentMissing'),
        button: this.$t('space-card.addPay')
      };
    },
    spaceEnter() {
      // return [SPACE_STATUS.ACTIVE, SPACE_STATUS.PENDING_CANCELLATION].includes(
      //   this.space.status
      // );
      return true;
    }
  }
});
